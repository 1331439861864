<template>
  <!-- 讲师管理 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
        <div class="main-content">
          <div class="main-content-header">
            <div class="item">
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="item">
              <a-button
                v-permission="'add'"
                type="primary"
                @click="
                  $jump({
                    path: '/storeAdd',
                  })
                "
              >
                新增门店
              </a-button>
            </div>
            <!-- <div class="item">
              <a-button
                v-permission="'add'"
                type="primary"
                icon="plus"
                @click="
                  $jump({
                    path: item.id === 1 ? '/lectorAdd' : '/trainingAdd',
                    query: { lectortype: item.id }
                  })
                "
              >
                录入讲师
              </a-button>
              <a-button v-permission="'export'"> 导出讲师花名册 </a-button>
            </div> -->
          </div>

          <my-table
            ref="table"
            :columns="columns"
            :data-source="item.data"
            :pagination="item.pagination"
            :loading="item.loading"
            :scroll="{ x: 1000, y: true }"
            @change="item.handlePaginationChange"
          >
            <template slot="type" slot-scope="record">
              {{ typeText[record.lectortype] }}
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].status"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="avatarUrl" slot-scope="record">
              <div class="avatarUrl">
                <img :src="record.avatarUrl" alt="avatarUrl" />
              </div>
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" v-permission="'info'">
                <router-link
                  :to="{
                    path: '/storeInfo',
                    query: { id: record.id }
                  }"
                >
                  更多详情
                </router-link>
              </a-button>
              <!-- <a-button type="link" v-permission="'edit'">
                <router-link
                  :to="{
                    path: '/',
                    query: { id: record.id }
                  }"
                >
                  编辑资料
                </router-link>
              </a-button> -->
              <a-button
                type="link"
                v-permission="'switch'"
                @click="setStatus(record.id, record.status, record.bcid)"
              >
                <span v-if="record.status === 1">禁用</span>
                <span v-else>启用</span>
              </a-button>
            </template>
          </my-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          key: 0,
          id: 1,
          name: '门店列表',
          data: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          },
          handlePaginationChange: this.handlePaginationChange1
        }
      ],
      tabIndex: 0,
      statuText: [
        {
          status: 'default',
          text: '禁用'
        },
        {
          status: 'success',
          text: '启用'
        }
      ],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '门店名称',
          width: 150,
          dataIndex: 'storename'
        },
        {
          title: '负责人',
          // width: 150,
          dataIndex: 'username'
        },
         {
          title: '手机号',
          // width: 150,
          dataIndex: 'mobile'
        },
        {
          title: '微信头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatarUrl'
          }
        },
        {
          title: '详细地址',
          // width: 150,
          dataIndex: 'address'
        },
        {
          title: '绑定地区名称',
          width: 150,
          dataIndex: 'businessAddressName'
        },
        {
          title: '操作',
          // width: 200,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      tableY: 300,
      typeText: {
        1: '门店列表'
        // 2: '实操讲师'
      },
    }
  },
  created() {
    this.getStore()
  },
   watch: {
    $route() {
       this.getStore()
    }
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (key === 0 && !this.tabs[key].data.length) {
        this.getStore()
      }
      //   if (key === 1 && !this.tabs[key].data.length) {
      //     this.getTraining()
      //   }
    },
    handlePaginationChange1(e) {
      const i = this.tabIndex
      const tab = this.tabs[i]
      console.log("tab",this.tabs)
      tab.loading = true
      tab.pagination.current = e.current
      this.getStore()
    },
    getStore() {
      // 获取门店列表
      const i = this.tabIndex
      const tab = this.tabs[i]
      console.log('tab', tab.pagination.current)
      const data = {
        page: tab.pagination.current,
        limit: 10
      }
      if (this.searchValue) {
        tab.loading = true
        data.keyword = this.searchValue
        data.page = 1
        tab.pagination.current = 1
      }
      this.$axios.getStore(data).then((res) => {
        const d = res.data.data
        tab.data = d.list
        // tab.pagination.total = d.count
        tab.loading = false
      })
    },
    // getTraining() {
    //   // 获取实操讲师列表
    //   const i = this.tabIndex
    //   const tab = this.tabs[i]
    //   const data = {
    //     page: tab.pagination.current,
    //     limit: 10
    //   }
    //   if (this.searchValue) {
    //     tab.loading = true
    //     data.keyword = this.searchValue
    //     data.page = 1
    //     tab.pagination.current = 1
    //   }
    //   this.$axios.getTraining(data).then((res) => {
    //     const d = res.data.data
    //     tab.data = d.list
    //     tab.pagination.total = d.count
    //     tab.loading = false
    //   })
    // },
    setStatus(id, status, bcid) {
      // 设置状态
      console.log('status', status)
      let title = ''
      if (status === 0) {
        title = '确定启用？'
        status = 1
      } else {
        title = '确定禁用？'
        status = 2
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            status,
            bcid
          }
          console.log('data', data)
          this.$axios.getStoreDisable(data).then(() => {
            this.$message.success('操作成功')
            this.getStore()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.table-wrap {
  height: 0;
}
.avatarUrl{
  width: 50px;
  height: 50px;
}
.avatarUrl img{
  width: 100%;
  height: 100%;
}
</style>
